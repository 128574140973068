import React from 'react'
import PropTypes from 'prop-types'

import './Content.css'

const Content = ({ content }) => (
  <div className="content">
    <div className="container">
      <div className="row">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </div>
)

Content.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Content
