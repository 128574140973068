import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ProgressBar from '../components/ProgressBar'

class Page extends Component {
  constructor() {
    super()

    this._isMounted = true

    this.state = {
      progressBar: 0,
    }
  }

  componentDidMount() {
    this._isMounted = true

    if (typeof window !== `undefined`) {
      this.handleProgressBar()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleProgressBar() {
    const { debounce } = require('../utils')

    window.addEventListener('scroll', debounce(() => {
      if (this._isMounted) {
        this.setState({
          progressBar: window.parseInt(100 * (window.pageYOffset / (document.body.scrollHeight - window.innerHeight))),
        })
      }
    }, 50))
  }

  render() {
    const { data: { page: {content, title}}} = this.props
    const { progressBar } = this.state

    return (
      <Layout className="page page-default">
        <SEO title={title} />
        <ProgressBar width={progressBar} />
        <div className="container">
          <h2 className="page__title">{title}</h2>
        </div>
        <Content content={content} />
      </Layout>
    )
  }
}

export default Page

export const query = graphql`
  query($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      content
      title
    }
  }
  `
