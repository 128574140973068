import React from 'react'
import PropTypes from 'prop-types'

import './ProgressBar.css'

const ProgressBar = ({ height, width }) => (
  <div className="progressbar" style={{ height }}>
    {typeof width !== 'undefined' ? (
      <div className="progressbar__inner" style={{ width: `${width.toString()}%`, transition: `width .3s linear` }} />
    ) : (
      <div className="progressbar__inner" />
    )}
  </div>
)

ProgressBar.defaultProps = {
  height: 1,
}

ProgressBar.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number,
}

export default ProgressBar
