/**
 * Debounce function
 * @param {Function} callback callback function
 * @param {Number} delay delay of debounce
 */
export const debounce = (callback, delay) => {
  let timer

  return function() {
    const args = arguments
    const context = this
    window.clearTimeout(timer)
    timer = window.setTimeout(function() {
      callback.apply(context, args)
    }, delay)
  }
}